<template>
  <m-container>
    <page-title
      :page-title="!$language ? 'Exam History Details' : 'পরীক্ষার বিবরণ'"
      class="mt-5"
    >
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="$router.replace({ name: 'exams-student' })"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </page-title>
    <div v-if="!renderNow" id="loading-wrapper">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <no-content-component
      v-else-if="noContentCondition"
      :message="examDataFetchFailMessage"
    ></no-content-component>
    <div v-else>
      <v-container class="pa-0">
        <v-row>
          <v-col md="9" xs="12" class="py-0">
            <div v-for="item in arrayForRender" :key="item.id" class="ma-10">
              <div v-if="item.questions">
                <essay-viewer :essay-content="item"></essay-viewer>
                <div v-for="question in item.questions" :key="question.id">
                  <question-answer-card-p
                    :value="wrap(examHistoryDetails.answers[question.id])"
                    :question="question"
                    :readonly="true"
                    @preview="preview(question.id)"
                  />
                </div>
              </div>
              <div v-else>
                <question-answer-card-p
                  :value="wrap(examHistoryDetails.answers[item.id])"
                  :question="item"
                  :readonly="true"
                  @preview="preview(item.id)"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="3" class="pl-0 pb-0 hidden-sm-and-down">
            <exam-history-right-box-p
              :obtained-score="examHistoryDetails.exam.score"
              :time-taken="examHistoryDetails.exam.time_taken"
              :answered="examHistoryDetails.exam.answered"
              :correct-answers="examHistoryDetails.exam.correct"
              :wrong-answers="wrongAnswers"
              :questions="examHistoryDetails.questions"
              :stats="answerStats"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        app
        style="z-index: 999"
      >
        <time-display
          :value="examHistoryDetails.exam.timeTaken || 0"
        ></time-display>
        <v-bottom-sheet v-model="sheet">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon>keyboard_arrow_down</v-icon></v-btn
            >
          </template>
          <v-sheet class="text-center" fullscreen>
            <exam-history-right-box-p
              :obtained-score="examHistoryDetails.exam.score"
              :answered="examHistoryDetails.exam.answered"
              :correct-answers="examHistoryDetails.exam.correct"
              :wrong-answers="wrongAnswers"
              :questions="examHistoryDetails.questions"
              :stats="answerStats"
            />
          </v-sheet>
        </v-bottom-sheet>
      </v-bottom-navigation>
    </div>
  </m-container>
</template>

<script>
import ExamHistoryRightBoxP from "#ef/exam/components/ExamHistoryRightBoxP.vue";
import TimeDisplay from "#ef/exam/components/TimeDisplay";
import QuestionAnswerCardP from "#ef/exam/components/QuestionAnswerCardP";
import NoContentComponent from "/global/components/NoContentComponent";
import { EquationFinder } from "#ef/exam/mixins";
import { keyBy } from "lodash";
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer";

export default {
  name: "ExamHistoryDetails",
  components: {
    EssayViewer,
    TimeDisplay,
    QuestionAnswerCardP,
    ExamHistoryRightBoxP,
    NoContentComponent,
  },
  mixins: [EquationFinder],
  props: {
    examId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      examHistoryDetails: {},
      renderNow: false,
      noContentCondition: false,
      sheet: false,
      essayMap: {},
      arrayForRender: [],
    };
  },
  computed: {
    answerStats() {
      const stats = {};
      const answers = this.examHistoryDetails.answers;

      for (let question of this.examHistoryDetails.questions) {
        const correctAnswer = question.answers.filter((a) => a.is_correct);
        let isCorrect = false;
        if (question.type === "mcq") {
          var correctId = [];
          for (let c of correctAnswer) {
            correctId.push(c.id);
          }
          isCorrect = answers[question.id]
            ? this.isRight(JSON.parse(answers[question.id]), correctId)
            : undefined;
        } else if (question.type === "fib") {
          isCorrect = answers[question.id]
            ? answers[question.id] === question.answers[0].item_text
            : undefined;
        } else {
          isCorrect = true;
        }
        stats[question.id] = {
          answerId: answers[question.id],
          correctAnswerId: correctAnswer.length > 1 ? correctAnswer.id : null,
          isCorrect: isCorrect,
          isSelected: answers[question.id] !== undefined,
        };
      }
      return stats;
    },
    wrongAnswers() {
      return (
        this.examHistoryDetails.exam.answered -
        this.examHistoryDetails.exam.correct
      );
    },
    selected() {
      return Object.values(this.selections).length;
    },
    totalQuestions() {
      return Object.values(this.questions).reduce(
        (acc, qs) => acc + qs.length,
        0
      );
    },
    ignored() {
      return this.totalQuestions - this.selected;
    },
    examDataFetchFailMessage() {
      return this.$language
        ? "পরীক্ষা সংক্রান্ত তথ্য নেই"
        : "There is no exam data";
    },
  },
  async created() {
    await this.getExamHistoryDetails();
    this.renderNow = true;
  },
  methods: {
    isRight(studentAnswer, rightAnswer) {
      return studentAnswer.sort().join(",") === rightAnswer.sort().join(",");
    },
    wrap(answer) {
      if (!answer) return [];
      if (typeof answer === "string" && answer.includes("[")) {
        return JSON.parse(answer);
      }
      return [answer];
    },
    preview(questionId) {
      // this function executed only for descriptive question
      // where answer file has been assessed
      this.$router.push({
        name: "assessment",
        params: { type: "exam", object_id: this.examId, index: questionId },
      });
    },
    async getExamHistoryDetails() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        this.noContentCondition = true;
        return;
      }
      try {
        this.examHistoryDetails = await this.$store.dispatch(
          "studentExam/getExamHistoryDetails",
          {
            studentExamId: this.examId,
          }
        );
        this.essayMap = keyBy(this.examHistoryDetails.essays, "id");
        let i = 0;
        await this.retrieveEquationsAndModifyQuestions(
          this.examHistoryDetails.questions,
          this.examId
        );
        this.examHistoryDetails.questions.sort((x, y) => {
          return x.order - y.order;
        });
        for (let question of this.examHistoryDetails.questions) {
          i += 1;
          if (question.essay_id) {
            const essay = this.essayMap[question.essay_id];
            if (essay.questions) essay.questions.push(question);
            else {
              this.arrayForRender.push(essay);
              essay.questions = [question];
            }
            question.shouldPick = false;
          } else this.arrayForRender.push(question);
        }
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>

<style lang="scss"></style>
