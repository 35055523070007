var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "page-title": !_vm.$language
            ? "Exam History Details"
            : "পরীক্ষার বিবরণ",
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "black", icon: "", large: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({ name: "exams-student" })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("arrow_back")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.renderNow
        ? _c("div", { attrs: { id: "loading-wrapper" } }, [
            _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
            _c("div", { attrs: { id: "loading-content" } }),
          ])
        : _vm.noContentCondition
        ? _c("no-content-component", {
            attrs: { message: _vm.examDataFetchFailMessage },
          })
        : _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { md: "9", xs: "12" } },
                        _vm._l(_vm.arrayForRender, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "ma-10" },
                            [
                              item.questions
                                ? _c(
                                    "div",
                                    [
                                      _c("essay-viewer", {
                                        attrs: { "essay-content": item },
                                      }),
                                      _vm._l(
                                        item.questions,
                                        function (question) {
                                          return _c(
                                            "div",
                                            { key: question.id },
                                            [
                                              _c("question-answer-card-p", {
                                                attrs: {
                                                  value: _vm.wrap(
                                                    _vm.examHistoryDetails
                                                      .answers[question.id]
                                                  ),
                                                  question: question,
                                                  readonly: true,
                                                },
                                                on: {
                                                  preview: function ($event) {
                                                    return _vm.preview(
                                                      question.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("question-answer-card-p", {
                                        attrs: {
                                          value: _vm.wrap(
                                            _vm.examHistoryDetails.answers[
                                              item.id
                                            ]
                                          ),
                                          question: item,
                                          readonly: true,
                                        },
                                        on: {
                                          preview: function ($event) {
                                            return _vm.preview(item.id)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0 pb-0 hidden-sm-and-down",
                          attrs: { cols: "3" },
                        },
                        [
                          _c("exam-history-right-box-p", {
                            attrs: {
                              "obtained-score":
                                _vm.examHistoryDetails.exam.score,
                              "time-taken":
                                _vm.examHistoryDetails.exam.time_taken,
                              answered: _vm.examHistoryDetails.exam.answered,
                              "correct-answers":
                                _vm.examHistoryDetails.exam.correct,
                              "wrong-answers": _vm.wrongAnswers,
                              questions: _vm.examHistoryDetails.questions,
                              stats: _vm.answerStats,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    { staticStyle: { "z-index": "999" }, attrs: { app: "" } },
                    [
                      _c("time-display", {
                        attrs: {
                          value: _vm.examHistoryDetails.exam.timeTaken || 0,
                        },
                      }),
                      _c(
                        "v-bottom-sheet",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("keyboard_arrow_down"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3703556110
                          ),
                          model: {
                            value: _vm.sheet,
                            callback: function ($$v) {
                              _vm.sheet = $$v
                            },
                            expression: "sheet",
                          },
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "text-center",
                              attrs: { fullscreen: "" },
                            },
                            [
                              _c("exam-history-right-box-p", {
                                attrs: {
                                  "obtained-score":
                                    _vm.examHistoryDetails.exam.score,
                                  answered:
                                    _vm.examHistoryDetails.exam.answered,
                                  "correct-answers":
                                    _vm.examHistoryDetails.exam.correct,
                                  "wrong-answers": _vm.wrongAnswers,
                                  questions: _vm.examHistoryDetails.questions,
                                  stats: _vm.answerStats,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }